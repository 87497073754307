import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-5PXNMSR'
}

/**
 * @description Init sanar GTM
 * @function
 * @name initSanarGtm
 */
export const initSanarGtm = () => {
  TagManager.initialize(tagManagerArgs)
}
